@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

:root {
  --poppins: "Poppins", sans-serif;
  --lato: "Lato", sans-serif;

  --light: #f9f9f9;
  --blue: #3c91e6;
  --light-blue: #cfe8ff;
  --grey: #eee;
  --dark-grey: #aaaaaa;
  --dark: #342e37;
  --red: #db504a;
  --yellow: #ffce26;
  --light-yellow: #fff2c6;
  --orange: #fd7238;
  --light-orange: #ffe0d3;
}

html {
  overflow-x: hidden;
}

body.dark {
  --light: #0c0c1e;
  --grey: #060714;
  --dark: #fbfbfb;
}

body {
  background: var(--grey);
  overflow-x: hidden;
}

/* SIDEBAR */
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 256px;
  height: 100%;
  background: url("./assets/img/Sidebar.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1000;
  font-family: var(--lato);
  transition: 0.3s ease;
  overflow-x: hidden;
  scrollbar-width: none;
}
#sidebar::--webkit-scrollbar {
  display: none;
}
#sidebar.hide {
  width: 0px;
}
#sidebar .brand {
  font-size: 24px;
  font-weight: 700;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  background: url("./assets/img/Sidebar.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 500;
  padding: 30px;
  box-sizing: content-box;
  text-decoration: none;
}
#sidebar .brand .bx {
  min-width: 60px;
  display: flex;
  justify-content: center;
}
#sidebar .brand .text_logo {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
#sidebar .side-menu {
  width: 90%;
  margin-top: 48px;
}
#sidebar .side-menu li {
  height: 48px;
  background: transparent;
  margin-left: 6px;

  padding: 14px, 81px, 14px, 31px;
  border-radius: 7px;
  border: 0px, 0px, 0px, 5px;
  gap: 12px;
  margin-bottom: 6px;
}
#sidebar .side-menu li.active {
  background: var(--grey);
  position: relative;
}
#sidebar .side-menu li.active .text {
  color: #1cbbce !important;
}
// #sidebar .side-menu li.active::before {
//   content: "";
//   position: absolute;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   top: -40px;
//   right: 0;
//   box-shadow: 20px 20px 0 var(--grey);
//   z-index: -1;
// }
// #sidebar .side-menu li.active::after {
//   content: "";
//   position: absolute;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   bottom: -40px;
//   right: 0;
//   box-shadow: 20px -20px 0 var(--grey);
//   z-index: -1;
// }
#sidebar .side-menu li a {
  width: 100%;
  height: 100%;
  // background: var(--light);
  display: flex;
  align-items: center;
  border-radius: 48px;
  color: #fff;
  white-space: nowrap;
  overflow-x: hidden;
  text-decoration: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
#sidebar .side-menu.top li.active a {
  color: #1cbbce !important;
}
#sidebar.hide .side-menu li a {
  width: calc(48px - (4px * 2));
  transition: width 0.3s ease;
}
#sidebar .side-menu li a.logout {
  color: var(--red);
}

#sidebar .side-menu.top li:hover {
  background: var(--grey);
  cursor: pointer;
}
#sidebar .side-menu.top li a:hover {
  color: #1cbbce;
}
#sidebar .side-menu li a .bx {
  min-width: calc(60px - ((4px + 6px) * 2));
  display: flex;
  justify-content: center;
}
/* SIDEBAR */

.menu {
  display: none;
}

/* CONTENT */
#content {
  position: relative;
  width: calc(100% - 256px);
  left: 256px;
  transition: 0.3s ease;
}
#sidebar.hide ~ #content {
  width: calc(100% - 0px);
  left: 0px;
}

/* NAVBAR */
#content nav {
  height: fit-content;
  background: #fff;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  font-family: var(--poppins);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 0px 24px;
}
#content nav .nav_info {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
#content nav .nav_info .title {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}
#content nav .nav_info .date_time {
  font-family: "DM Sans";
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}

#content nav::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 0;
  border-radius: 50%;
  box-shadow: -20px -20px 0 var(--light);
}
#content nav a {
  color: var(--dark);
}
#content nav .bx.bx-menu {
  cursor: pointer;
  color: var(--dark);
}
#content nav .nav-link {
  font-size: 16px;
  transition: 0.3s ease;
}
#content nav .nav-link:hover {
  color: var(--blue);
}
#content nav form {
  max-width: 400px;
  width: 100%;
  margin-right: auto;
}
#content nav form .form-input {
  display: flex;
  align-items: center;
  height: 36px;
}
#content nav form .form-input input {
  flex-grow: 1;
  padding: 0 16px;
  height: 100%;
  border: none;
  background: var(--grey);
  border-radius: 36px 0 0 36px;
  outline: none;
  width: 100%;
  color: var(--dark);
}
#content nav form .form-input button {
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue);
  color: var(--light);
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 0 36px 36px 0;
  cursor: pointer;
}
#content nav .notification {
  font-size: 20px;
  position: relative;
}
#content nav .notification .num {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--light);
  background: var(--red);
  color: var(--light);
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#content nav .profile img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
#content nav .switch-mode {
  display: block;
  min-width: 50px;
  height: 25px;
  border-radius: 25px;
  background: var(--grey);
  cursor: pointer;
  position: relative;
}
#content nav .switch-mode::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: calc(25px - 4px);
  background: var(--blue);
  border-radius: 50%;
  transition: all 0.3s ease;
}
#content nav #switch-mode:checked + .switch-mode::before {
  left: calc(100% - (25px - 4px) - 2px);
}
/* NAVBAR */

/* MAIN */
#content main {
  width: 100%;
  padding: 36px 24px;
  font-family: var(--poppins);
  // max-height: calc(100vh - 71px);
  overflow-y: auto;
}
#content main .head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
#content main .head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--dark);
}
#content main .head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
#content main .head-title .left .breadcrumb li {
  color: var(--dark);
}
#content main .head-title .left .breadcrumb li a {
  color: var(--dark-grey);
  pointer-events: none;
}
#content main .head-title .left .breadcrumb li a.active {
  color: var(--blue);
  pointer-events: unset;
}
#content main .head-title .btn-download {
  height: 36px;
  padding: 0 16px;
  border-radius: 36px;
  background: var(--blue);
  color: var(--light);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  font-weight: 500;
}

#content main .box-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;
  margin-top: 36px;
}
#content main .box-info li {
  padding: 24px;
  background: var(--light);
  border-radius: 20px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
}
#content main .box-info li .bx {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#content main .box-info li:nth-child(1) .bx {
  background: var(--light-blue);
  color: var(--blue);
}
#content main .box-info li:nth-child(2) .bx {
  background: var(--light-yellow);
  color: var(--yellow);
}
#content main .box-info li:nth-child(3) .bx {
  background: var(--light-orange);
  color: var(--orange);
}
#content main .box-info li .text h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--dark);
}
#content main .box-info li .text p {
  color: var(--dark);
}

#content main .table-data {
  display: flex;
  flex-wrap: wrap;
  // grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}
#content main .table-data > div {
  border-radius: 20px;
  // background: var(--light);
  padding: 24px;
  overflow-x: auto;
}
#content main .table-data .head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}
#content main .table-data .head h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}
#content main .table-data .head .bx {
  cursor: pointer;
}

#content main .table-data .order {
  flex-grow: 1;
  flex-basis: 700px;
  background: #ffffff;
}

#content main .table-data .order table {
  width: 100%;
  border-collapse: collapse;
}
#content main .table-data .order table th {
  padding-bottom: 12px;
  font-size: 13px;
  text-align: left;
  border-bottom: 1px solid var(--grey);
}
#content main .table-data .order table td {
  padding: 16px 0;
}
#content main .table-data .order table tr td:first-child {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding-left: 6px;
}
#content main .table-data .order table td img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
#content main .table-data .order table td p {
  margin-top: 0;
  margin: 0px;
  margin-bottom: 0;
}
#content main .table-data .order table tbody tr:hover {
  background: var(--grey);
}
#content main .table-data .order table tr td .status {
  font-family: Poppins;
  line-height: 22px;
  letter-spacing: 0.0024em;
  text-align: right;

  // font-size: 10px;
  padding: 6px 16px;
  color: var(--light);
  // border-radius: 20px;
  // font-weight: 700;
}
#content main .table-data .order table tr td .status.completed {
  background: var(--blue);
}
#content main .table-data .order table tr td .status.process {
  background: var(--yellow);
}
#content main .table-data .order table tr td .status.pending {
  background: var(--orange);
}
#content main .table-data .order table tr td .status.debit {
  font-size: 14px;
  font-weight: 600;
  color: #ed1c25 !important;
}
#content main .table-data .order table tr td .status.credit {
  font-size: 14px;
  font-weight: 600;
  color: #1cbbce !important;
}
#content main .table-data .order table tr td .status.big_gray {
  font-size: 14px;
  font-weight: 600;
  color: #8f92a1 !important;
}
#content main .table-data .order table tr td .status.small_gray {
  font-size: 12px;
  font-weight: 400;
  color: #8f92a1 !important;
}
#content main .table-data .order table tr td .status.big_navy_blue {
  padding: 0px;
  font-size: 12px;
  font-weight: 600;
  color: #2f394e !important;
}

#content main .table-data .todo {
  flex-grow: 1;
  flex-basis: 200px;
  background: #ffffff;
  box-shadow: 0px 5px 30px 0px #00000008;
}
#content main .table-data .todo .todo-list {
  width: 100%;
}
#content main .table-data .todo .todo-list li {
  width: 100%;
  margin-bottom: 16px;
  background: var(--grey);
  border-radius: 10px;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#content main .table-data .todo .todo-list li .bx {
  cursor: pointer;
}
#content main .table-data .todo .todo-list li.completed {
  border-left: 10px solid var(--blue);
}
#content main .table-data .todo .todo-list li.not-completed {
  border-left: 10px solid var(--orange);
}
#content main .table-data .todo .todo-list li:last-child {
  margin-bottom: 0;
}
/* MAIN */
/* CONTENT */

@media screen and (max-width: 768px) {
  #sidebar {
    width: 200px;
  }
  .menu {
    display: block;
  }

  #content {
    width: calc(100% - 60px);
    left: 200px;
  }

  #content nav .nav-link {
    display: none;
  }

  .user-profile {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  #content nav form .form-input input {
    display: none;
  }

  #content nav form .form-input button {
    width: auto;
    height: auto;
    background: transparent;
    border-radius: none;
    color: var(--dark);
  }

  #content nav form.show .form-input input {
    display: block;
    width: 100%;
  }
  #content nav form.show .form-input button {
    width: 36px;
    height: 100%;
    border-radius: 0 36px 36px 0;
    color: var(--light);
    background: var(--red);
  }

  #content nav form.show ~ .notification,
  #content nav form.show ~ .profile {
    display: none;
  }
  #content main {
    max-height: calc(100vh - 144px);
  }

  #content main .box-info {
    grid-template-columns: 1fr;
  }

  #content main .table-data .head {
    min-width: 420px;
  }
  #content main .table-data .order table {
    min-width: 731px;
  }
  #content main .table-data .todo .todo-list {
    min-width: 420px;
  }
}

/* You can add global styles to this file, and also import other style files */
div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(16, 16, 16, 0.5);
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.uil-ring-css {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}
.uil-ring-css > div {
  position: absolute;
  display: block;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 80px;
  box-shadow: 0 6px 0 0 #ffffff;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}

.fade.show {
  background: rgba(0, 0, 0, 0.5) !important;
}

/* Load Spinner  */

.lds-spinner {
  display: flex;
  justify-content: center;
}

.lds-spinner--center {
  align-items: center !important;
}

.lds-spinner::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #dddddd;
  border-top-color: #30323e00;
  border-bottom-color: #30323e00;
  border-radius: 50%;
  animation: lds-spinner 1s ease infinite;
}

@keyframes lds-spinner {
  to {
    transform: rotate(1turn);
  }
}

/* End Load Spinner */

input,
select,
textarea,
page-item {
  border: 1px solid #c3c7e5 !important;
  box-shadow: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  color: #000000 !important;

  &:focus {
    border: 1px solid #fde428 !important;
    // font-size: 12px;
  }
  &::placeholder {
    color: #c3c7e5 !important;
    font-size: 12px;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .user {
    width: 100%;
    //   margin-left: 15px;
    font-size: 24px;
    font-family: "DM Sans";
    font-weight: 700;
    color: #333333;

    p {
      padding-bottom: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .small {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 125%;
      padding: 0px;
      margin: 0px;
      /* identical to box height, or 12px */

      color: #333333;
    }
  }
}

.user-profile {
  display: flex;
  align-items: center;

  .notification {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 30px;

    img {
      cursor: pointer;
    }
  }

  .user-dropdown {
    display: inline-flex;
    flex-direction: row;
    //   justify-content: space-between;
    align-items: center;
    padding: 6px 15px 3px 15px;
    margin-left: 50px;
    //   height: 48px;

    background: rgba(28, 187, 206, 0.1);
    border-radius: 100px;

    p {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      // line-height: 17px;
      margin: 0px;
      padding: 0px 3px 0px 0px;
      margin-right: 5px;
      white-space: nowrap;
      width: 7rem;
      overflow: hidden;
      text-overflow: ellipsis;

      /* Text Color/Text 1 */

      color: #1b212d;
    }
    &:hover {
      background: rgba(28, 187, 206, 0.1);
    }
    & img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
}

.user_contant {
  display: flex;
  align-items: start;
  flex-direction: column;
  // gap: 10px;
  padding-left: 12px;

  p,
  span {
    margin: 0px;
  }
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    /* identical to box height, or 12px */

    color: #333333;
  }
}

// .dropdown-item:focus, .dropdown-item:active {
//   background: inherit;
// }

// @media (max-width: 767.98px) {
//   .user-profile {
//     .user-dropdown {
//       margin-left: 20px;
//       p {
//         display: none;
//       }
//     }
//   }
// }

.skeleton-loader {
  width: 100px; /* Adjust according to your design */
  height: 20px; /* Adjust according to your design */
  background-color: #f0f0f0; /* Light gray background color */
  border-radius: 4px; /* Rounded corners */
  animation: pulse 1.5s infinite ease-in-out; /* Animation with infinite loop */
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0; /* Light gray */
  }
  50% {
    background-color: #e0e0e0; /* Slightly darker gray */
  }
  100% {
    background-color: #f0f0f0; /* Back to light gray */
  }
}

.back_btn {
  display: flex;
  flex-direction: row;
  gap: 30px;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-atlasGreen {
  padding: 12px 24px 12px 24px !important;
  border-radius: 6px !important;
  background-color: #1cbbce !important;
  color: #fff !important;
}

.btn-atlasGreen.with-icon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}


.close-btn-pop {
  position: absolute;
  top: 24px;
  right: 24px;
  background: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  width: 92%;
  align-items: center;

  span {
    text-transform: capitalize;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.input-group {
  input {
    border-left: 1px solid transparent !important;
    &:focus {
      border-right: 1px solid #c3c7e5 !important;
      border-top: 1px solid #c3c7e5 !important;
      border-bottom: 1px solid #c3c7e5 !important;
      // font-size: 12px;
    }
  }
  .input-group-text {
    border: 1px solid #c3c7e5;
    border-right: 1px solid transparent !important;
    background-color: transparent !important;
    color: #c3c7e5;
  }
}

.acc_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 12px;
  border-radius: 6px;
  border: 1px solid #c3c7e5;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.acc_box:hover {
  transform: scale(0.95);
}

.acc_box span {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.acc_box span.bank_box {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 5px;
}

.acc_box span h4 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #343434;
  margin: 0px;
  padding: 0px;
}

.acc_box span .img_boder {
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    #1cbbce 0%,
    rgba(12, 102, 113, 0.3125) 50%,
    rgba(253, 228, 40, 0.02) 100%
  );
}

.acc_box span h4 i {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 200;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #343434;
}

.acc_box span small {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #1cbbce;
}



.is__invalid-input {
  border-color: #e11d48 !important;
}

.is__invalid-error {
  color: #e11d48;
  @apply px-1 pt-1 text-xs;
}
